'use client';
import OfferPayout from '@/components/OfferPayout';
import { Offer, OfferV6, Vote } from '@/model/offer';
import {
  convertUnixTimeStamp,
  getOfferType,
  secondsToReadableTime,
} from '@/utils';
import Button from '@/components/ui/Button';
import Link from 'next/link';
import LazyImage from '@/components/LazyImage';
import LogOnViewPort from '@/components/LogOnViewPort';
import {
  OFFER_CARD_CLICK,
  OFFER_CARD_VIEW,
  OFFER_CTA_CLICKED_V2,
} from '@/constants/logs';
import { AndroidService } from '@/service/Android';
import TimeIcon from 'public/assets/icons/time.svg';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';
import UpIcon from 'public/assets/icons/upvote_filled.svg';
import Spacer from '@/components/ui/Spacer';

/**
 *
 * @param {Object} props
 * @param {OfferV6} props.offer
 * @param {Vote} props.offer.vote
 * @returns
 */
export default function ActiveOfferCard({ offer, index, tagMap }) {
  const ttfp = offer.metrics?.ttfp_ms / 1000;
  const tags = offer.tags?.filter(d => d !== tagMap?.NEW.id);
  const isNew = !!offer.tags?.find(d => d === tagMap?.NEW.id);

  return (
    <LogOnViewPort
      logPayload={{
        offer_id: offer.offerId,
        position: index,
      }}
      event={OFFER_CARD_VIEW}
    >
      <div className="flex flex-col gap-1 rounded-xl bg-card-bg-color relative">
        <Link
          href={`/offer/${offer?.offerId}`}
          className={`grid grid-cols-[60px_1fr] items-center p-4 gap-4`}
          scroll={false}
        >
          <LazyImage
            src={offer?.iconImage}
            alt={offer?.title}
            className="w-[60px] h-[60px] rounded-lg self-start"
          />
          <div className="flex flex-col justify-between">
            <h1 className="flex-1 text-lg leading-none line-clamp-2 text-ellipsis max-w-[250px]">
              {offer?.title}
            </h1>
            <div className="mt-2 flex flex-row justify-between">
              <p className="rounded-full px-3 py-1 self-center bg-white border border-black text-sm w-fit text-black line-clamp-1 text-ellipsis">
                {getOfferType(offer.offerType)}
              </p>
              <Button
                variant="solid"
                className="self-center px-3 py-0 mt-auto font-bold"
              >
                <OfferPayout size="medium" amount={offer?.payout?.amount} />
              </Button>
            </div>
          </div>
        </Link>
        {(!!ttfp || !!tags?.length || offer.metrics.upvotes > 0) && (
          <div className="flex rounded-b-lg items-center justify-between gap-4  bg-accent-color p-2">
            <div className="flex flex-wrap gap-2">
              {ttfp ? (
                <div className="flex items-center gap-1 text-accent-color">
                  <TimeIcon className="text-xs text-white" />
                  <p className="text-xs text-white">
                    {secondsToReadableTime(ttfp)}
                  </p>
                  {!!tags?.length && (
                    <span className="w-1 h-1 ml-2 rounded-full bg-slate-300" />
                  )}
                </div>
              ) : null}
              {tags?.length ? (
                <>
                  {tags.map((d, i) => (
                    <div className="flex items-center">
                      {i !== 0 && (
                        <span className="w-1 h-1 mr-2 rounded-full bg-slate-300" />
                      )}
                      <p className="text-xs text-white">{tagMap[d].display}</p>
                    </div>
                  ))}
                </>
              ) : null}
            </div>
            {offer.metrics.upvotes > 0 && (
              <div className="flex items-center gap-1 ml-auto">
                <p className="mt-1 text-sm text-white">
                  {offer.metrics.upvotes}
                </p>
                <UpIcon className={'mb-1 text-white'} />
              </div>
            )}
          </div>
        )}
        {isNew && (
          <div className="absolute right-0 top-0 flex items-center justify-center bg-[#DBA32C] h-fit w-fit p-1 rounded-tr-lg rounded-bl-lg">
            <p className="text-xs font-medium text-black">
              {LANGUAGES_CONTENT[StorageService.getLanguage()].home.tags.NEW}
            </p>
          </div>
        )}
      </div>
    </LogOnViewPort>
  );
}
