'use client';
import { GAMES_PAGE, MAF_PAGE, SPIN_WIN_PAGE } from '@/constants/routes.const';
import useAuthContext from '@/context/authContext';
import { AndroidService } from '@/service/Android';
import Image from 'next/image';
import Link from 'next/link';
import { useEffect, useMemo, useState } from 'react';
import {
  getRemoteConfig,
  getValue,
  fetchAndActivate,
} from 'firebase/remote-config';
import { firebaseApp } from '@/configs/firebase';
import Skeleton from 'react-loading-skeleton';
import { LANGUAGES_CONTENT } from '@/constants/language';
import { StorageService } from '@/service/StorageService';

export default function ExternalSDKs() {
  const { authUser } = useAuthContext();
  let userId = authUser?.userId;
  const [adjoeEnabled, setAdjoeEnabled] = useState(false);
  const [bitlabsEnabled, setBitlabsEnabled] = useState(false);
  const [mafEnabled, setMafEnabled] = useState(false);
  const [wowEnabled, setWowEnabled] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const remoteConfig = getRemoteConfig(firebaseApp);
    remoteConfig.settings.minimumFetchIntervalMillis = 60;
    remoteConfig.defaultConfig = {
      adjoe: false,
    };
    fetchAndActivate(remoteConfig)
      .then(() => {
        const adjoeEnabled = getValue(remoteConfig, 'adjoe').asBoolean();
        const bitlabsEnabled = getValue(remoteConfig, 'bitlabs').asBoolean();
        const mafEnabled = getValue(remoteConfig, 'maf').asBoolean();
        const wowEnabled = getValue(remoteConfig, 'wow').asBoolean();
        setAdjoeEnabled(adjoeEnabled);
        setBitlabsEnabled(bitlabsEnabled);
        setMafEnabled(mafEnabled);
        setWowEnabled(wowEnabled);
        setIsLoaded(true);
      })
      .catch(err => {
        console.log('Error fetching remote config', err);
      });
  }, []);

  useEffect(() => {
    AndroidService.initOfferwall(
      'ADJOE',
      process.env.NEXT_PUBLIC_ADJOE_SDK_KEY,
      userId
    );
    AndroidService.initOfferwall(
      'BITLABS',
      process.env.NEXT_PUBLIC_BITLABS_SDK_KEY,
      userId
    );
  }, [userId]);

  const sdkTitleData =
    LANGUAGES_CONTENT[StorageService.getLanguage()]['home'].sdk;

  const EXTERNAL_SDKS = useMemo(
    () => [
      // {
      //   src: '/assets/images/wow.png',
      //   alt: 'Wow By PubSCale',
      //   title: 'Premium Offers',
      //   hide: !wowEnabled,
      //   onClick: () => {
      //     //   AndroidService.launchUrlCCT(
      //     //     `${
      //     //       process.env.NEXT_PUBLIC_WOW_OFFERWALL
      //     //     }&user_id=${StorageService.getUserId()}`
      //     //   );
      //     AndroidService.openUrlInBrowser(
      //       `${
      //         process.env.NEXT_PUBLIC_WOW_OFFERWALL
      //       }&user_id=${StorageService.getUserId()}`
      //     );
      //   },
      // },
      {
        src: '/assets/images/adjoe.svg',
        alt: 'adjoe',
        title: sdkTitleData.play,
        isNew: true,
        hide: !adjoeEnabled,
        onClick: () => {
          AndroidService.launchOfferwall('ADJOE');
        },
      },
      {
        src: '/assets/images/survey_reward.svg',
        alt: 'survey_reward',
        title: sdkTitleData.survey,
        isNew: false,
        hide: !bitlabsEnabled,
        onClick: () => {
          AndroidService.launchOfferwall('BITLABS');
        },
      },
      {
        src: '/assets/images/spin_wheel.svg',
        alt: 'spin_wheel',
        title: sdkTitleData.spin,
        link: SPIN_WIN_PAGE,
      },
      {
        src: '/assets/images/games_icon.svg',
        alt: 'games_icon',
        title: sdkTitleData.games,
        link: GAMES_PAGE,
      },
      {
        src: '/assets/images/maficon.svg',
        alt: 'maf_icon',
        title: 'Extra rewards',
        link: MAF_PAGE,
        hide: !mafEnabled,
      },
    ],
    [userId, adjoeEnabled, bitlabsEnabled, mafEnabled]
  );
  return (
    <div className="flex items-start gap-2 overflow-x-auto py-2">
      {!isLoaded
        ? Array(EXTERNAL_SDKS.length - 1)
            .fill(0)
            .map((_, index) => (
              <div key={index} className="flex flex-col items-center gap-1 ">
                <Skeleton borderRadius={6} width={60} height={60} />
                <Skeleton borderRadius={6} width={70} height={15} />
              </div>
            ))
        : EXTERNAL_SDKS.filter(item => !item.hide).map((item, index) => (
            <Item key={index + item.title} {...item} />
          ))}
    </div>
  );
}

function Item({ src, alt, title, isNew, link, onClick }) {
  return (
    <Link
      className="relative flex flex-col items-center gap-1"
      href={link || '#'}
      onClick={e => {
        if (onClick) {
          e.preventDefault();
          onClick();
        }
        if (!link) {
          e.preventDefault();
        }
      }}
    >
      {isNew && (
        <div className="absolute top-[-4px] right-[-4px] bg-red-500 text-white text-xs rounded-full px-1">
          New
        </div>
      )}
      <Image
        alt={alt}
        src={src}
        width={60}
        height={60}
        className="rounded-lg"
      />
      <div className="text-xs w-[70px] text-center">{title}</div>
    </Link>
  );
}
